import {Domain} from '../interfaces/siteSelect.interface';
import {betRiversCom} from './betrivers.com';
import {landingSiteBetriversComRushstreetinteractiveCom}
  from './landing-site-betrivers-com.rushstreetinteractive.com';
import {betRiversNet} from './betrivers.net';
import {landingSiteBetriversNetRushstreetinteractiveCom}
  from './landing-site-betrivers-net.rushstreetinteractive.com';
import {playSugarHouseCom} from './playsugarhouse.com';
import {landingSitePlaysugarhouseComRushstreetinteractiveCom}
  from './landing-site-playsugarhouse-com.rushstreetinteractive.com';

export const siteSelectConfigurations: {[key: string]: Domain } = {
  'betrivers.com': betRiversCom,
  'landing-site-betrivers-com.rushstreetinteractive.com': landingSiteBetriversComRushstreetinteractiveCom,

  'betrivers.net': betRiversNet,
  'landing-site-betrivers-net.rushstreetinteractive.com': landingSiteBetriversNetRushstreetinteractiveCom,

  'playsugarhouse.com': playSugarHouseCom,
  'landing-site-playsugarhouse-com.rushstreetinteractive.com': landingSitePlaysugarhouseComRushstreetinteractiveCom,
};
