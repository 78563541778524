import {Domain} from '../interfaces/siteSelect.interface';

export const landingSiteBetriversComRushstreetinteractiveCom: Domain = {
  appliedDomain: 'landing-site-betrivers-com.rushstreetinteractive.com',
  cookieDomain: '.rushstreetinteractive.com',
  countryConfigs: [
    {
      countryCodes: [
        'US',
      ],
      stateConfigs: [
        {
          skinCode: 'us-nj-rivers-p4r',
          siteUrl: 'https://portal-us-nj-rivers-p4r-stg.rushstreetinteractive.com',
          stateCodes: [
            'NJ',
          ],
          brandName: 'BetRivers New Jersey',
        },
        {
          skinCode: 'pa-rivers-p4r',
          siteUrl: 'https://portal-pa-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'PA',
          ],
          brandName: 'BetRivers Pennsylvania',
        },
        {
          skinCode: 'in-rivers-p4r',
          siteUrl: 'https://portal-in-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'IN',
          ],
          brandName: 'BetRivers Indiana',
        },
        {
          skinCode: 'de-rivers-p4r',
          siteUrl: '',
          stateCodes: [
            'DE',
          ],
          brandName: 'BetRivers Delaware',
          subCagesPageDescription: '<p>In Delaware there are more ways to play.</p><p>Choose your casino below.</p>',
          stateName: 'Delaware',
          subCages: [
            {
              skinCode: 'us-de-dp-p4r',
              siteUrl: 'https://portal-us-de-dp-p4r-stg.rushstreetinteractive.com/',
              stateCodes: [
                'DE',
              ],
              brandName: 'DE Park Casino',
            },
            {
              skinCode: 'us-de-hrc-p4r',
              siteUrl: 'https://portal-us-de-hrc-p4r-stg.rushstreetinteractive.com/',
              stateCodes: [
                'DE',
              ],
              brandName: 'Harrington Casino',
            },
          ],
        },
        {
          skinCode: 'ia-rivers-p4r',
          siteUrl: 'https://portal-ia-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'IA',
          ],
          brandName: 'BetRivers Iowa',
        },
        {
          skinCode: 'il-rivers-p4r',
          siteUrl: 'https://portal-il-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'IL',
          ],
          brandName: 'BetRivers Illinois',
        },
        {
          skinCode: 'mi-rivers-p4r',
          siteUrl: 'https://portal-mi-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'MI',
          ],
          brandName: 'BetRivers Michigan',
        },
        {
          skinCode: 'colorado-rivers-p4r',
          siteUrl: 'https://portal-colorado-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'CO',
          ],
          brandName: 'BetRivers Colorado',
        },
        {
          skinCode: 'us-az-rivers-p4r',
          siteUrl: 'https://portal-us-az-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'AZ',
          ],
          brandName: 'BetRivers Arizona',
        },
        {
          skinCode: 'us-la-rivers-p4r',
          siteUrl: 'https://portal-us-la-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'LA',
          ],
          brandName: 'BetRivers Louisiana',
        },
        {
          skinCode: 'us-md-rivers-p4r',
          siteUrl: 'https://portal-us-md-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'MD',
          ],
          brandName: 'BetRivers Maryland',
        },
        {
          skinCode: 'va-rivers-p4r',
          siteUrl: 'https://portal-va-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'VA',
          ],
          brandName: 'BetRivers Virginia',
        },
        {
          skinCode: 'wv-rivers-p4r',
          siteUrl: 'https://portal-wv-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'WV',
          ],
          brandName: 'BetRivers West Virginia',
          clearAfter: true,
        },
        {
          skinCode: 'betrivers-c4f',
          siteUrl: 'https://portal-betrivers-c4f-stg.rushstreetinteractive.com/',
          stateCodes: [],
          brandName: 'BetRivers.net Casino4Fun',
          noCookie: true,
        },
        {
          skinCode: 'can-betrivers-c4f',
          siteUrl: 'https://portal-can-betrivers-c4f-stg.rushstreetinteractive.com/',
          stateCodes: [],
          brandName: 'Canada BetRivers.net Casino4Fun',
          noCookie: true,
          clearAfter: true,
        },
      ],
    },
    {
      countryCodes: [
        'CA',
      ],
      stateConfigs: [
        {
          skinCode: 'ca-on-rivers-p4r',
          siteUrl: 'https://portal-ca-on-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'ON',
          ],
          brandName: 'BetRivers Ontario',
          clearAfter: true,
        },
      ],
    },
  ],
};
