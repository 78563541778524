import {Domain} from '../interfaces/siteSelect.interface';

export const landingSitePlaysugarhouseComRushstreetinteractiveCom: Domain =  {
  appliedDomain: 'landing-site-playsugarhouse-com.rushstreetinteractive.com',
  cookieDomain: '.rushstreetinteractive.com',
  defaultConfig: {
    skinCode: 'pa-sh-p4r',
    siteUrl: 'https://portal-pa-sh-p4r-stg.rushstreetinteractive.com/',
    brandName: 'PlaySugarHouse Pennsylvania',
  },
  appForceSiteConfig: {
    skinCode: 'us-nj-rivers-p4r',
    siteUrl: 'https://portal-us-nj-rivers-p4r-stg.rushstreetinteractive.com/',
    brandName: 'BetRivers New Jersey',
  },
  countryConfigs: [
    {
      countryCodes: [
        'US',
      ],
      stateConfigs: [
        {
          skinCode: 'pa-sh-p4r',
          siteUrl: 'https://portal-pa-sh-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'PA',
          ],
          brandName: 'PlaySugarHouse Pennsylvania',
          clearAfter: true,
        },
        {
          skinCode: 'us-nj-rivers-p4r',
          siteUrl: 'https://portal-us-nj-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'NJ',
          ],
          brandName: 'BetRivers New Jersey',
          noCookie: true,
          skinCodeMappings: [
            'nj-sh-p4r',
            'nj-rivers-p4r',
            'us-nj-rivers-p4r',
          ],
          autoRedirectConfiguration: {
            url: 'https://portal-us-nj-rivers-p4r-stg.rushstreetinteractive.com/',
            queryParams: {
              setSite: 'us-nj-rivers-p4r',
            },
          },
        },
        {
          skinCode: 'in-rivers-p4r',
          siteUrl: 'https://portal-in-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'IN',
          ],
          brandName: 'BetRivers Indiana',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'ia-rivers-p4r',
          siteUrl: 'https://portal-ia-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'IA',
          ],
          brandName: 'BetRivers Iowa',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'il-rivers-p4r',
          siteUrl: 'https://portal-il-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'IL',
          ],
          brandName: 'BetRivers Illinois',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'mi-rivers-p4r',
          siteUrl: 'https://portal-mi-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'MI',
          ],
          brandName: 'BetRivers Michigan',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'colorado-rivers-p4r',
          siteUrl: 'https://portal-colorado-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'CO',
          ],
          brandName: 'BetRivers Colorado',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-az-rivers-p4r',
          siteUrl: 'https://portal-us-az-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'AZ',
          ],
          brandName: 'BetRivers Arizona',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-la-rivers-p4r',
          siteUrl: 'https://portal-us-la-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'LA',
          ],
          brandName: 'BetRivers Louisiana',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-md-rivers-p4r',
          siteUrl: 'https://portal-us-md-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'MD',
          ],
          brandName: 'BetRivers Maryland',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'va-rivers-p4r',
          siteUrl: 'https://portal-va-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'VA',
          ],
          brandName: 'BetRivers Virginia',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'wv-rivers-p4r',
          siteUrl: 'https://portal-wv-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'WV',
          ],
          brandName: 'BetRivers West Virginia',
          noCookie: true,
          clearAfter: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'betrivers-c4f',
          siteUrl: 'https://portal-betrivers-c4f-stg.rushstreetinteractive.com/',
          stateCodes: [],
          brandName: 'BetRivers.net Casino4Fun',
          noCookie: true,
        },
        {
          skinCode: 'can-betrivers-c4f',
          siteUrl: 'https://portal-can-betrivers-c4f-stg.rushstreetinteractive.com/',
          stateCodes: [],
          brandName: 'Canada BetRivers.net Casino4Fun',
          noCookie: true,
          clearAfter: true,
        },
      ],
    },
    {
      countryCodes: [
        'CA',
      ],
      stateConfigs: [
        {
          skinCode: 'ca-on-rivers-p4r',
          siteUrl: 'https://portal-ca-on-rivers-p4r-stg.rushstreetinteractive.com/',
          stateCodes: [
            'ON',
          ],
          brandName: 'BetRivers Ontario',
          clearAfter: true,
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://landing-site-betrivers-com.rushstreetinteractive.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
      ],
    },
  ],
};
