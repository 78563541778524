import {Domain} from '../interfaces/siteSelect.interface';

export const betRiversNet: Domain = {
  appliedDomain: 'betrivers.net',
  cookieDomain: '.betrivers.net',
  defaultConfig: {
    skinCode: 'betrivers-c4f',
    siteUrl: 'https://us.betrivers.net/',
    brandName: 'BetRivers.net Casino4Fun',
  },
  countryConfigs: [
    {
      countryCodes: [
        'US',
      ],
      skinCode: 'betrivers-c4f',
      siteUrl: 'https://us.betrivers.net/',
      brandName: 'BetRivers.net Casino4Fun',
      clearAfter: true,
    },
    {
      countryCodes: [
        'CA',
      ],
      skinCode: 'can-betrivers-c4f',
      siteUrl: 'https://can.betrivers.net/',
      brandName: 'Canada BetRivers.net Casino4Fun',
      clearAfter: true,
    },
  ],
};
