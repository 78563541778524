import {siteSelectConfigurations} from '../../../shared/configuration/siteSelect';
import {Domain, SiteConfig} from '../../../shared/interfaces/siteSelect.interface';

/**
 * Uses current `url` to find corresponding Domain config
 * @param url Current site url
 * @return Found Domain config
 */
export function getDomainConfig(url: string): Domain | null {
  return Object.values(siteSelectConfigurations).find(
    (domain) => url.includes(domain.appliedDomain),
  ) || null;
}

/**
 * Uses current `url` to find corresponding `cookieDomain` name
 * @param url Current site url
 * @return Found Domain name
 */
export function getCookieDomain(url: string): string | null {
  return getDomainConfig(url)?.cookieDomain || null;
}


/**
 * Site config type guard
 * @param config
 * @return Is current config type of `SiteConfig`
 */
export function isSiteConfig(config: unknown): config is SiteConfig {
  // @ts-expect-error Variable can be anything and we validate its correct object
  return !!config.skinCode;
}
