import {Domain} from '../interfaces/siteSelect.interface';

export const landingSiteBetriversNetRushstreetinteractiveCom: Domain = {
  appliedDomain: 'landing-site-betrivers-net.rushstreetinteractive.com',
  cookieDomain: '.rushstreetinteractive.com',
  defaultConfig: {
    skinCode: 'rushgames-c4f',
    siteUrl: 'https://portal-rushgames-c4f-stg.rushstreetinteractive.com/',
    brandName: 'Rushgames Casino4Fun',
  },
  countryConfigs: [
    {
      countryCodes: [
        'US',
      ],
      skinCode: 'rushgames-c4f',
      siteUrl: 'https://portal-rushgames-c4f-stg.rushstreetinteractive.com/',
      brandName: 'Rushgames Casino4Fun',
      clearAfter: true,
    },
    {
      countryCodes: [
        'CA',
      ],
      skinCode: 'can-betrivers-c4f',
      siteUrl: 'https://portal-can-betrivers-c4f-stg.rushstreetinteractive.com/',
      brandName: 'Canada BetRivers.net Casino4Fun',
      clearAfter: true,
    },
  ],
};
