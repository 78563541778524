import {Domain} from '../interfaces/siteSelect.interface';

export const playSugarHouseCom: Domain = {
  appliedDomain: 'playsugarhouse.com',
  cookieDomain: '.playsugarhouse.com',
  defaultConfig: {
    skinCode: 'pa-sh-p4r',
    siteUrl: 'https://pa.playsugarhouse.com/',
    brandName: 'PlaySugarHouse Pennsylvania',
  },
  appForceSiteConfig: {
    skinCode: 'us-nj-rivers-p4r',
    siteUrl: 'https://nj.betrivers.com/',
    brandName: 'BetRivers New Jersey',
  },
  countryConfigs: [
    {
      countryCodes: [
        'US',
      ],
      stateConfigs: [
        {
          skinCode: 'pa-sh-p4r',
          siteUrl: 'https://pa.playsugarhouse.com/',
          stateCodes: [
            'PA',
          ],
          brandName: 'PlaySugarHouse Pennsylvania',
          clearAfter: true,
        },
        {
          skinCode: 'us-ny-rivers-p4r',
          siteUrl: 'https://ny.betrivers.com/',
          stateCodes: [
            'NY',
          ],
          brandName: 'BetRivers New York',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-oh-rivers-p4r',
          siteUrl: 'https://oh.betrivers.com/',
          stateCodes: [
            'OH',
          ],
          brandName: 'BetRivers Ohio',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-nj-rivers-p4r',
          siteUrl: 'https://nj.betrivers.com/',
          stateCodes: [
            'NJ',
          ],
          brandName: 'BetRivers New Jersey',
          noCookie: true,
          skinCodeMappings: [
            'nj-sh-p4r',
            'nj-rivers-p4r',
            'us-nj-rivers-p4r',
          ],
          autoRedirectConfiguration: {
            url: 'https://nj.betrivers.com/',
            queryParams: {
              setSite: 'us-nj-rivers-p4r',
            },
          },
        },
        {
          skinCode: 'in-rivers-p4r',
          siteUrl: 'https://in.betrivers.com/',
          stateCodes: [
            'IN',
          ],
          brandName: 'BetRivers Indiana',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'il-rivers-p4r',
          siteUrl: 'https://il.betrivers.com/',
          stateCodes: [
            'IL',
          ],
          brandName: 'BetRivers Illinois',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'mi-rivers-p4r',
          siteUrl: 'https://mi.betrivers.com/',
          stateCodes: [
            'MI',
          ],
          brandName: 'BetRivers Michigan',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'ia-rivers-p4r',
          siteUrl: 'https://ia.betrivers.com/',
          stateCodes: [
            'IA',
          ],
          brandName: 'BetRivers Iowa',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'colorado-rivers-p4r',
          siteUrl: 'https://co.betrivers.com/',
          stateCodes: [
            'CO',
          ],
          brandName: 'BetRivers Colorado',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'de-rivers-p4r',
          siteUrl: '',
          stateCodes: [
            'DE',
          ],
          brandName: 'BetRivers Delaware',
          stateName: 'Delaware',
          subCagesPageDescription: '<p>In Delaware there are more ways to play.</p><p>Choose your casino below.</p>',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
          subCages: [
            {
              skinCode: 'us-de-dp-p4r',
              siteUrl: 'https://delawarepark.betrivers.com/',
              stateCodes: [
                'DE',
              ],
              brandName: 'DE Park Casino',
            },
            {
              skinCode: 'us-de-hrc-p4r',
              siteUrl: 'https://harrington.betrivers.com/',
              stateCodes: [
                'DE',
              ],
              brandName: 'Harrington Casino',
              clearAfter: true,
            },
          ],
        },
        {
          skinCode: 'us-az-rivers-p4r',
          siteUrl: 'https://az.betrivers.com/',
          stateCodes: [
            'AZ',
          ],
          brandName: 'BetRivers Arizona',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-la-rivers-p4r',
          siteUrl: 'https://la.betrivers.com/',
          stateCodes: [
            'LA',
          ],
          brandName: 'BetRivers Louisiana',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'us-md-rivers-p4r',
          siteUrl: 'https://md.betrivers.com/',
          stateCodes: [
            'MD',
          ],
          brandName: 'BetRivers Maryland',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'va-rivers-p4r',
          siteUrl: 'https://va.betrivers.com/',
          stateCodes: [
            'VA',
          ],
          brandName: 'BetRivers Virginia',
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'wv-rivers-p4r',
          siteUrl: 'https://wv.betrivers.com/',
          stateCodes: [
            'WV',
          ],
          brandName: 'BetRivers West Virginia',
          clearAfter: true,
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
        {
          skinCode: 'betrivers-c4f',
          siteUrl: 'https://us.betrivers.net/',
          stateCodes: [],
          brandName: 'BetRivers.net Casino4Fun',
          noCookie: true,
        },
        {
          skinCode: 'can-betrivers-c4f',
          siteUrl: 'https://can.betrivers.net/',
          stateCodes: [],
          brandName: 'Canada BetRivers.net Casino4Fun',
          noCookie: true,
          clearAfter: true,
        },
      ],
    },
    {
      countryCodes: [
        'CA',
      ],
      stateConfigs: [
        {
          skinCode: 'ca-on-rivers-p4r',
          siteUrl: 'https://on.betrivers.ca/',
          stateCodes: [
            'ON',
          ],
          brandName: 'BetRivers Ontario',
          clearAfter: true,
          noCookie: true,
          autoRedirectConfiguration: {
            url: 'https://betrivers.com/',
            queryParams: {
              reselectState: 1,
            },
          },
        },
      ],
    },
  ],
};
