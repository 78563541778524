import {getCookieDomain} from './helpers/siteSelect.helpers';

/**
 * On selecting a state from UI, redirect user with all current url properties
 * and remember the selection of the state.
 *
 *
 * Export is used to keep it from being removed by treeshaking.
 * Used by injecting code (in prerender.entry.ts) to html and called from there.
 *
 * @param event JS link selection event
 * @param targetUrl Target url to be redirected to
 * @param skinCode Skin code used to remember the selection
 * @param setCookie Whether or not set the cookie
 */
export function stateSelectionClick(
  event: Event,
  targetUrl: string,
  skinCode: string,
  setCookie: boolean,
): void {
  event.preventDefault();
  if (setCookie) {
    storeSelection(skinCode);
  }

  window.location.href =
    (targetUrl.endsWith('/') ? targetUrl : `${targetUrl}/`) +
    window.location.pathname.substring(1) +
    window.location.search +
    window.location.hash;
}

/**
 * Toggle the subCages view
 * @param skinCode
 * @param event
 */
export function toggleSubCages(skinCode: string, event?: MouseEvent): void {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  const mainCagesView = document.querySelector('.main-cages-container');
  const subCagesView = document.getElementById(skinCode);

  mainCagesView.classList.toggle('hidden');
  subCagesView.classList.toggle('hidden');
}

/**
 * Set cookie with the provided site skin code.
 * @param skinCode Skin code of the site.
 */
export function storeSelection(skinCode: string): void {
  const date = new Date();
  date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
  const cookieDomain = getCookieDomain(window.location.href);
  if (cookieDomain) {
    document.cookie = `knownLocation=${skinCode}; expires=${date.toUTCString()};domain=${cookieDomain};path=/`;
  }
}

/**
 * @param cookieName Name of the cookie to be cleared
 */
export function cookieRemover(cookieName: string): void {
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}
